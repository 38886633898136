/*Media Queries*/
/*Transitions*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

.main-container {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  max-height: 100vh; }
  .main-container .page-ctn {
    background-color: white;
    max-height: 100vh; }
    .main-container .page-ctn .header-ctn {
      max-height: 100vh;
      position: relative; }
      .main-container .page-ctn .header-ctn .navbar-ctn {
        display: flex;
        position: fixed;
        width: 100%;
        top: 0%;
        left: 0%;
        z-index: 6;
        text-align: left;
        height: 50px; }
        .main-container .page-ctn .header-ctn .navbar-ctn .logo-btn {
          height: 50px;
          background-color: #081d3c;
          z-index: 6; }
          .main-container .page-ctn .header-ctn .navbar-ctn .logo-btn .logo-img {
            height: 30px;
            z-index: 6; }
        .main-container .page-ctn .header-ctn .navbar-ctn button {
          color: white;
          height: 50px;
          z-index: 6; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .navbar-ctn button {
              width: 25%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .navbar-ctn button {
              width: 25%; } }
      .main-container .page-ctn .header-ctn .navbar-btn-ctn {
        width: 100%;
        animation-delay: 0s;
        animation-duration: 0.5s; }
      .main-container .page-ctn .header-ctn .mobile-menu {
        display: none; }
        @media only screen and (max-width: 480px) {
          .main-container .page-ctn .header-ctn .mobile-menu {
            display: block; } }
      .main-container .page-ctn .header-ctn .header-main {
        background-color: #081d3c;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 100vh; }
        .main-container .page-ctn .header-ctn .header-main .particles {
          height: 100vh; }
        .main-container .page-ctn .header-ctn .header-main .header-name {
          color: white;
          position: absolute;
          top: 35%;
          left: 0%;
          z-index: 5;
          font-size: 100px;
          font-weight: 500;
          width: 100%; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .header-main .header-name {
              font-size: 3em;
              top: 30%;
              width: 80%;
              left: 10%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .header-main .header-name {
              font-size: 2em;
              top: 25%;
              width: 80%;
              left: 10%; } }
        .main-container .page-ctn .header-ctn .header-main .header-title {
          color: #ff5e39;
          position: absolute;
          letter-spacing: 25px;
          top: 50%;
          left: 0%;
          z-index: 5;
          font-size: 36px;
          width: 100%;
          font-weight: 300;
          text-transform: uppercase;
          animation-delay: 4.5s;
          animation-duration: 1.5s;
          line-height: 1.6; }
          @media only screen and (min-width: 961px) and (max-width: 1200px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 30px;
              letter-spacing: 15px;
              top: 60%; } }
          @media only screen and (min-width: 641px) and (max-width: 960px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 2em;
              letter-spacing: 15px;
              top: 60%;
              width: 80%;
              left: 10%; } }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 2em;
              letter-spacing: 15px;
              top: 50%;
              width: 80%;
              left: 10%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 1em;
              letter-spacing: 5px;
              top: 45%;
              width: 80%;
              left: 10%; } }
      .main-container .page-ctn .header-ctn .contact-header-ctn {
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: 75%;
        left: 40%;
        width: 20%; }
        .main-container .page-ctn .header-ctn .contact-header-ctn img {
          margin: 15px;
          transition: 0.2s ease-in; }
          .main-container .page-ctn .header-ctn .contact-header-ctn img:hover {
            transform: scale(1.1); }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .contact-header-ctn img {
              margin: 10px;
              height: 50px; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .contact-header-ctn img {
              margin: 5px;
              height: 50px; } }
      .main-container .page-ctn .header-ctn .btn-scroll-down {
        position: absolute;
        top: 95%;
        left: 40%;
        width: 20%; }
