@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

/*Media Queries*/
/*Transitions*/
.skills-panel-ctn {
  width: 100%;
  font-family: "Montserrat"; }
  .skills-panel-ctn .skills-panel-title {
    color: #ff5e39;
    font-size: 53px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 0;
    margin: -5px 0px; }
    @media only screen and (min-width: 961px) and (max-width: 1200px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 43px; } }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 53px; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 33px; } }
    @media only screen and (max-width: 480px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 33px; } }
  .skills-panel-ctn .skills-panel-list-ctn {
    margin: auto;
    border-radius: 10px; }
    .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
      margin-top: 50px;
      border-radius: 10px;
      background-color: #081d3c; }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
          margin-top: 30px; } }
      @media only screen and (max-width: 480px) {
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
          margin-top: 30px; } }
      .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
        font-size: 30px;
        border-radius: 10px;
        font-weight: 500;
        height: 80px;
        line-height: 80px;
        background-color: #081d3c;
        color: white; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
            font-size: 20px;
            height: 50px;
            line-height: 50px; } }
        @media only screen and (max-width: 480px) {
          .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
            font-size: 20px;
            height: 50px;
            line-height: 50px; } }
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content.skills-panel-content-light {
          background-color: #0f2547; }
