/*Media Queries*/
/*Transitions*/
.project-page-ctn {
  position: relative;
  text-align: left; }
  .project-page-ctn .background-img {
    z-index: 1;
    height: 100vh;
    width: 100%;
    object-fit: cover; }
  .project-page-ctn .blue-filter {
    background-color: #081d3c;
    height: 100vh;
    width: 100%;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9; }
  .project-page-ctn .project-mosaic {
    position: absolute;
    top: 8%;
    left: 8%;
    z-index: 6;
    cursor: pointer; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-mosaic {
        width: 30px; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-mosaic {
        width: 30px; } }
  .project-page-ctn .project-ctn .project-img {
    position: absolute;
    top: 30%;
    left: 15%;
    z-index: 6;
    width: 35%;
    border-radius: 20px; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-img {
        top: 15%;
        left: 25%;
        z-index: 6;
        width: 50%; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-img {
        top: 15%;
        left: 25%;
        width: 50%; } }
  .project-page-ctn .project-ctn .project-content {
    position: absolute;
    top: 30%;
    left: 55%;
    z-index: 6;
    width: 35%; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-content {
        top: 50%;
        left: 25%;
        width: 50%; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-content {
        top: 40%;
        left: 25%;
        width: 50%; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-ctn .project-content {
        top: 20%;
        left: 10%;
        width: 80%; } }
    .project-page-ctn .project-ctn .project-content .project-title {
      font-size: 73px;
      text-transform: uppercase;
      color: white;
      font-weight: 900;
      width: 80%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 53px; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 33px; } }
      @media only screen and (max-width: 480px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 23px; } }
    .project-page-ctn .project-ctn .project-content .project-description {
      color: white;
      margin: 50px 0px;
      line-height: 20px; }
    .project-page-ctn .project-ctn .project-content .project-tags {
      display: flex;
      justify-content: left;
      margin: 5px;
      z-index: 6; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      @media only screen and (max-width: 480px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
        background-color: #ff5e39;
        cursor: default;
        margin: 5px;
        color: #081d3c;
        font-weight: 800;
        font-style: italic; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
            margin: 2px; } }
        @media only screen and (max-width: 480px) {
          .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
            margin: 1px; } }
  .project-page-ctn .project-ctn .project-demo {
    z-index: 6;
    width: 12%;
    color: white;
    font-size: 25px;
    position: absolute;
    top: 85%;
    left: 44%;
    transition: 0.2s ease-in;
    cursor: pointer; }
    .project-page-ctn .project-ctn .project-demo:hover {
      transform: scale(1.1); }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 20%;
        left: 40%;
        font-size: 15px; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 40%;
        left: 30%;
        font-size: 15px; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 50%;
        left: 25%;
        font-size: 15px; } }
