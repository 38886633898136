@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900);
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

/*Media Queries*/
/*Transitions*/
.skills-panel-ctn {
  width: 100%;
  font-family: "Montserrat"; }
  .skills-panel-ctn .skills-panel-title {
    color: #ff5e39;
    font-size: 53px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 0;
    margin: -5px 0px; }
    @media only screen and (min-width: 961px) and (max-width: 1200px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 43px; } }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 53px; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 33px; } }
    @media only screen and (max-width: 480px) {
      .skills-panel-ctn .skills-panel-title {
        font-size: 33px; } }
  .skills-panel-ctn .skills-panel-list-ctn {
    margin: auto;
    border-radius: 10px; }
    .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
      margin-top: 50px;
      border-radius: 10px;
      background-color: #081d3c; }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
          margin-top: 30px; } }
      @media only screen and (max-width: 480px) {
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list {
          margin-top: 30px; } }
      .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
        font-size: 30px;
        border-radius: 10px;
        font-weight: 500;
        height: 80px;
        line-height: 80px;
        background-color: #081d3c;
        color: white; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
            font-size: 20px;
            height: 50px;
            line-height: 50px; } }
        @media only screen and (max-width: 480px) {
          .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content {
            font-size: 20px;
            height: 50px;
            line-height: 50px; } }
        .skills-panel-ctn .skills-panel-list-ctn .skills-panel-list .skills-panel-content.skills-panel-content-light {
          background-color: #0f2547; }

/*Media Queries*/
/*Transitions*/
.skills-section {
  background-image: url(/static/media/bqckground-logo.39b01360.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: auto;
  width: 100%; }
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (min-width: 641px) and (max-width: 960px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (max-width: 480px) {
    .skills-section {
      max-height: none; } }
  .skills-section .skills-ctn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    flex-wrap: wrap; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        max-width: 90%;
        margin: auto; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        max-width: 90%;
        margin: auto; } }
    @media only screen and (max-width: 480px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        margin: auto;
        max-width: 90%; } }
    .skills-section .skills-ctn .dev-skills-ctn {
      margin: 150px 15px;
      width: 20%; }
      @media only screen and (min-width: 961px) and (max-width: 1200px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 50px auto;
          width: 40%; } }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }
      @media only screen and (max-width: 480px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }

/*Media Queries*/
/*Transitions*/
.card-ctn {
  position: relative;
  width: 450px;
  max-height: 300px;
  margin: 10px;
  border: none;
  border-radius: 20px;
  max-width: 450px;
  transition: 0.2s ease-in 0.1s;
  border-radius: 20px;
  cursor: pointer; }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .card-ctn {
      max-width: 350px; } }
  @media only screen and (max-width: 480px) {
    .card-ctn {
      max-width: 250px; } }
  .card-ctn:hover > .overlay {
    bottom: 0;
    height: 100%; }
  .card-ctn .project-image {
    width: 450px;
    object-fit: cover;
    height: 300px;
    border-radius: 20px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .card-ctn .project-image {
        max-width: 350px;
        max-height: 200px; } }
    @media only screen and (max-width: 480px) {
      .card-ctn .project-image {
        max-width: 250px;
        max-height: 150px; } }
  .card-ctn .overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: 0.9;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .4s ease;
    border-radius: 20px;
    background-color: #ff5e39; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .card-ctn .overlay {
        height: 100%;
        opacity: 1;
        bottom: 0;
        background-color: transparent; } }
    @media only screen and (max-width: 480px) {
      .card-ctn .overlay {
        height: 100%;
        opacity: 1;
        bottom: 0;
        background-color: transparent; } }
    .card-ctn .overlay .text {
      white-space: nowrap;
      color: white;
      font-size: 20px;
      position: absolute;
      overflow: hidden;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-transform: uppercase;
      font-weight: 800; }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .card-ctn .overlay .text {
          color: #ff5e39; } }
      @media only screen and (max-width: 480px) {
        .card-ctn .overlay .text {
          color: #ff5e39; } }
    .card-ctn .overlay .blurb {
      white-space: nowrap;
      color: white;
      font-size: 18px;
      position: absolute;
      word-wrap: break-word;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%); }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .card-ctn .overlay .blurb {
          color: #ff5e39;
          font-weight: 500; } }
      @media only screen and (max-width: 480px) {
        .card-ctn .overlay .blurb {
          color: #ff5e39;
          font-weight: 500; } }

/*Media Queries*/
/*Transitions*/
.project-page-ctn {
  position: relative;
  text-align: left; }
  .project-page-ctn .background-img {
    z-index: 1;
    height: 100vh;
    width: 100%;
    object-fit: cover; }
  .project-page-ctn .blue-filter {
    background-color: #081d3c;
    height: 100vh;
    width: 100%;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9; }
  .project-page-ctn .project-mosaic {
    position: absolute;
    top: 8%;
    left: 8%;
    z-index: 6;
    cursor: pointer; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-mosaic {
        width: 30px; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-mosaic {
        width: 30px; } }
  .project-page-ctn .project-ctn .project-img {
    position: absolute;
    top: 30%;
    left: 15%;
    z-index: 6;
    width: 35%;
    border-radius: 20px; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-img {
        top: 15%;
        left: 25%;
        z-index: 6;
        width: 50%; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-img {
        top: 15%;
        left: 25%;
        width: 50%; } }
  .project-page-ctn .project-ctn .project-content {
    position: absolute;
    top: 30%;
    left: 55%;
    z-index: 6;
    width: 35%; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-content {
        top: 50%;
        left: 25%;
        width: 50%; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-content {
        top: 40%;
        left: 25%;
        width: 50%; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-ctn .project-content {
        top: 20%;
        left: 10%;
        width: 80%; } }
    .project-page-ctn .project-ctn .project-content .project-title {
      font-size: 73px;
      text-transform: uppercase;
      color: white;
      font-weight: 900;
      width: 80%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 53px; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 33px; } }
      @media only screen and (max-width: 480px) {
        .project-page-ctn .project-ctn .project-content .project-title {
          font-size: 23px; } }
    .project-page-ctn .project-ctn .project-content .project-description {
      color: white;
      margin: 50px 0px;
      line-height: 20px; }
    .project-page-ctn .project-ctn .project-content .project-tags {
      display: flex;
      justify-content: left;
      margin: 5px;
      z-index: 6; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      @media only screen and (max-width: 480px) {
        .project-page-ctn .project-ctn .project-content .project-tags {
          display: block; } }
      .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
        background-color: #ff5e39;
        cursor: default;
        margin: 5px;
        color: #081d3c;
        font-weight: 800;
        font-style: italic; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
            margin: 2px; } }
        @media only screen and (max-width: 480px) {
          .project-page-ctn .project-ctn .project-content .project-tags .btn-tags {
            margin: 1px; } }
  .project-page-ctn .project-ctn .project-demo {
    z-index: 6;
    width: 12%;
    color: white;
    font-size: 25px;
    position: absolute;
    top: 85%;
    left: 44%;
    transition: 0.2s ease-in;
    cursor: pointer; }
    .project-page-ctn .project-ctn .project-demo:hover {
      transform: scale(1.1); }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 20%;
        left: 40%;
        font-size: 15px; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 40%;
        left: 30%;
        font-size: 15px; } }
    @media only screen and (max-width: 480px) {
      .project-page-ctn .project-ctn .project-demo {
        width: 50%;
        left: 25%;
        font-size: 15px; } }

/*Media Queries*/
/*Transitions*/
/*Media Queries*/
/*Transitions*/
.projects-main {
  background-color: #081d3c;
  padding-top: 100px;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; }
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    .projects-main {
      max-height: none; } }
  @media only screen and (min-width: 641px) and (max-width: 960px) {
    .projects-main {
      max-height: none; } }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .projects-main {
      padding-top: 50px;
      max-height: none; } }
  @media only screen and (max-width: 480px) {
    .projects-main {
      max-height: none;
      padding-top: 30px; } }
  .projects-main .projects-title {
    color: white;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 0;
    margin: auto; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .projects-main .projects-title {
        font-size: 53px; } }
    @media only screen and (max-width: 480px) {
      .projects-main .projects-title {
        font-size: 33px; } }
  .projects-main .projects-ctn {
    display: flex;
    display: -webkit-flex;
    margin: auto;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 95%;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .projects-main .projects-ctn {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media only screen and (max-width: 480px) {
      .projects-main .projects-ctn {
        padding-top: 50px;
        padding-bottom: 50px; } }

.project-page-main {
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s; }

/*Media Queries*/
/*Transitions*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

.contact-main-ctn {
  background-image: url(/static/media/bqckground-logo.39b01360.png);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px 0; }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .contact-main-ctn {
      display: block; } }
  @media only screen and (max-width: 480px) {
    .contact-main-ctn {
      display: block; } }
  .contact-main-ctn .about-title {
    color: #ff5e39;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding-top: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .about-title {
        font-size: 33px;
        padding-top: 50px; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .about-title {
        font-size: 33px;
        padding-top: 30px; } }
  .contact-main-ctn .about-title2 {
    color: #ff5e39;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding-bottom: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .about-title2 {
        font-size: 33px;
        padding-bottom: 50px; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .about-title2 {
        font-size: 33px;
        padding-bottom: 30px; } }
  .contact-main-ctn .contact-content-ctn {
    display: flex;
    justify-content: space-around; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .contact-main-ctn .contact-content-ctn {
        display: block;
        margin: auto; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .contact-content-ctn {
        display: block;
        margin: auto; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .contact-content-ctn {
        margin: auto;
        display: block; } }
    .contact-main-ctn .contact-content-ctn .contact-image-ctn {
      width: 40%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      @media only screen and (max-width: 480px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
        width: 350px;
        border-radius: 50%;
        margin: auto;
        overflow: hidden; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
            width: 250px; } }
        @media only screen and (max-width: 480px) {
          .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
            width: 250px; } }
    .contact-main-ctn .contact-content-ctn .contact-ctn {
      width: 60%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      @media only screen and (max-width: 480px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
        margin: auto;
        text-align: left; }
        @media only screen and (min-width: 641px) and (max-width: 960px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        @media only screen and (max-width: 480px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
          font-size: 34px;
          font-weight: 800;
          font-style: italic;
          margin: 25px 0px; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
              font-size: 24px; } }
          @media only screen and (max-width: 480px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
              font-size: 24px; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
          width: 80%;
          word-wrap: break-word;
          font-size: 25px;
          font-weight: 500;
          padding-top: 25px;
          padding-bottom: 50px;
          line-height: 45px; }
          @media only screen and (min-width: 641px) and (max-width: 960px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
          @media only screen and (max-width: 480px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn {
          width: 100%; }
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
            margin: 5px;
            transition: 0.2s ease-in; }
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo:hover {
              transform: scale(1.1); }
            @media only screen and (min-width: 481px) and (max-width: 640px) {
              .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
                height: 50px; } }
            @media only screen and (max-width: 480px) {
              .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
                height: 50px; } }

/*Media Queries*/
/*Transitions*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

.main-container {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  max-height: 100vh; }
  .main-container .page-ctn {
    background-color: white;
    max-height: 100vh; }
    .main-container .page-ctn .header-ctn {
      max-height: 100vh;
      position: relative; }
      .main-container .page-ctn .header-ctn .navbar-ctn {
        display: flex;
        position: fixed;
        width: 100%;
        top: 0%;
        left: 0%;
        z-index: 6;
        text-align: left;
        height: 50px; }
        .main-container .page-ctn .header-ctn .navbar-ctn .logo-btn {
          height: 50px;
          background-color: #081d3c;
          z-index: 6; }
          .main-container .page-ctn .header-ctn .navbar-ctn .logo-btn .logo-img {
            height: 30px;
            z-index: 6; }
        .main-container .page-ctn .header-ctn .navbar-ctn button {
          color: white;
          height: 50px;
          z-index: 6; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .navbar-ctn button {
              width: 25%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .navbar-ctn button {
              width: 25%; } }
      .main-container .page-ctn .header-ctn .navbar-btn-ctn {
        width: 100%;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s; }
      .main-container .page-ctn .header-ctn .mobile-menu {
        display: none; }
        @media only screen and (max-width: 480px) {
          .main-container .page-ctn .header-ctn .mobile-menu {
            display: block; } }
      .main-container .page-ctn .header-ctn .header-main {
        background-color: #081d3c;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 100vh; }
        .main-container .page-ctn .header-ctn .header-main .particles {
          height: 100vh; }
        .main-container .page-ctn .header-ctn .header-main .header-name {
          color: white;
          position: absolute;
          top: 35%;
          left: 0%;
          z-index: 5;
          font-size: 100px;
          font-weight: 500;
          width: 100%; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .header-main .header-name {
              font-size: 3em;
              top: 30%;
              width: 80%;
              left: 10%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .header-main .header-name {
              font-size: 2em;
              top: 25%;
              width: 80%;
              left: 10%; } }
        .main-container .page-ctn .header-ctn .header-main .header-title {
          color: #ff5e39;
          position: absolute;
          letter-spacing: 25px;
          top: 50%;
          left: 0%;
          z-index: 5;
          font-size: 36px;
          width: 100%;
          font-weight: 300;
          text-transform: uppercase;
          -webkit-animation-delay: 4.5s;
                  animation-delay: 4.5s;
          -webkit-animation-duration: 1.5s;
                  animation-duration: 1.5s;
          line-height: 1.6; }
          @media only screen and (min-width: 961px) and (max-width: 1200px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 30px;
              letter-spacing: 15px;
              top: 60%; } }
          @media only screen and (min-width: 641px) and (max-width: 960px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 2em;
              letter-spacing: 15px;
              top: 60%;
              width: 80%;
              left: 10%; } }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 2em;
              letter-spacing: 15px;
              top: 50%;
              width: 80%;
              left: 10%; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .header-main .header-title {
              font-size: 1em;
              letter-spacing: 5px;
              top: 45%;
              width: 80%;
              left: 10%; } }
      .main-container .page-ctn .header-ctn .contact-header-ctn {
        display: flex;
        justify-content: space-around;
        position: absolute;
        top: 75%;
        left: 40%;
        width: 20%; }
        .main-container .page-ctn .header-ctn .contact-header-ctn img {
          margin: 15px;
          transition: 0.2s ease-in; }
          .main-container .page-ctn .header-ctn .contact-header-ctn img:hover {
            transform: scale(1.1); }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .main-container .page-ctn .header-ctn .contact-header-ctn img {
              margin: 10px;
              height: 50px; } }
          @media only screen and (max-width: 480px) {
            .main-container .page-ctn .header-ctn .contact-header-ctn img {
              margin: 5px;
              height: 50px; } }
      .main-container .page-ctn .header-ctn .btn-scroll-down {
        position: absolute;
        top: 95%;
        left: 40%;
        width: 20%; }

.fa-spin-fast {
  -webkit-animation: fa-spin-fast .2s infinite linear;
  animation: fa-spin-fast .2s infinite linear; }

@-webkit-keyframes fa-spin-fast {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes fa-spin-fast {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

/*Media Queries*/
/*Transitions*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  margin: 0;
  padding: 0; }

@media only screen and (max-width: 480px) {
  .hideOnMobile {
    display: none !important; } }

@media only screen and (max-width: 480px) {
  .mobileonly {
    display: block !important; } }

@media only screen and (min-width: 481px) and (max-width: 640px) {
  .hideOnSmallScreen {
    display: none !important; } }

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 8px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px white;
  border-radius: 20px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 10px;
  height: 200px; }

