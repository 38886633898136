/*Media Queries*/
/*Transitions*/
/*Media Queries*/
/*Transitions*/
.projects-main {
  background-color: #081d3c;
  padding-top: 100px;
  animation-duration: 0.2s; }
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    .projects-main {
      max-height: none; } }
  @media only screen and (min-width: 641px) and (max-width: 960px) {
    .projects-main {
      max-height: none; } }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .projects-main {
      padding-top: 50px;
      max-height: none; } }
  @media only screen and (max-width: 480px) {
    .projects-main {
      max-height: none;
      padding-top: 30px; } }
  .projects-main .projects-title {
    color: white;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 0;
    margin: auto; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .projects-main .projects-title {
        font-size: 53px; } }
    @media only screen and (max-width: 480px) {
      .projects-main .projects-title {
        font-size: 33px; } }
  .projects-main .projects-ctn {
    display: flex;
    display: -webkit-flex;
    margin: auto;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 95%;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .projects-main .projects-ctn {
        padding-top: 50px;
        padding-bottom: 50px; } }
    @media only screen and (max-width: 480px) {
      .projects-main .projects-ctn {
        padding-top: 50px;
        padding-bottom: 50px; } }

.project-page-main {
  animation-delay: 0;
  animation-duration: 0.2s; }
