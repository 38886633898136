@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

/*Media Queries*/
/*Transitions*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  margin: 0;
  padding: 0; }

@media only screen and (max-width: 480px) {
  .hideOnMobile {
    display: none !important; } }

@media only screen and (max-width: 480px) {
  .mobileonly {
    display: block !important; } }

@media only screen and (min-width: 481px) and (max-width: 640px) {
  .hideOnSmallScreen {
    display: none !important; } }

/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 8px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px white;
  border-radius: 20px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 10px;
  height: 200px; }
