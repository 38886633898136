/*Media Queries*/
/*Transitions*/
.card-ctn {
  position: relative;
  width: 450px;
  max-height: 300px;
  margin: 10px;
  border: none;
  border-radius: 20px;
  max-width: 450px;
  transition: 0.2s ease-in 0.1s;
  border-radius: 20px;
  cursor: pointer; }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .card-ctn {
      max-width: 350px; } }
  @media only screen and (max-width: 480px) {
    .card-ctn {
      max-width: 250px; } }
  .card-ctn:hover > .overlay {
    bottom: 0;
    height: 100%; }
  .card-ctn .project-image {
    width: 450px;
    object-fit: cover;
    height: 300px;
    border-radius: 20px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .card-ctn .project-image {
        max-width: 350px;
        max-height: 200px; } }
    @media only screen and (max-width: 480px) {
      .card-ctn .project-image {
        max-width: 250px;
        max-height: 150px; } }
  .card-ctn .overlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    opacity: 0.9;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .4s ease;
    border-radius: 20px;
    background-color: #ff5e39; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .card-ctn .overlay {
        height: 100%;
        opacity: 1;
        bottom: 0;
        background-color: transparent; } }
    @media only screen and (max-width: 480px) {
      .card-ctn .overlay {
        height: 100%;
        opacity: 1;
        bottom: 0;
        background-color: transparent; } }
    .card-ctn .overlay .text {
      white-space: nowrap;
      color: white;
      font-size: 20px;
      position: absolute;
      overflow: hidden;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-transform: uppercase;
      font-weight: 800; }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .card-ctn .overlay .text {
          color: #ff5e39; } }
      @media only screen and (max-width: 480px) {
        .card-ctn .overlay .text {
          color: #ff5e39; } }
    .card-ctn .overlay .blurb {
      white-space: nowrap;
      color: white;
      font-size: 18px;
      position: absolute;
      word-wrap: break-word;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%); }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .card-ctn .overlay .blurb {
          color: #ff5e39;
          font-weight: 500; } }
      @media only screen and (max-width: 480px) {
        .card-ctn .overlay .blurb {
          color: #ff5e39;
          font-weight: 500; } }
