/*Media Queries*/
/*Transitions*/
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600|Source+Serif+Pro:400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,800i,900");
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600; }

h1 {
  font-size: 2em;
  letter-spacing: 0.03em; }

h2 {
  font-size: 1.5em;
  letter-spacing: 0.025em; }

h3 {
  font-size: 1.25em;
  letter-spacing: 0.02em; }

h4 {
  font-size: 1.15em;
  letter-spacing: 0.02em; }

h5 {
  font-size: 1em;
  letter-spacing: 0.01em; }

h6 {
  font-size: 0.9em;
  letter-spacing: 0.01em; }

p {
  line-height: 1.2em; }

.contact-main-ctn {
  background-image: url("../../../../style/images/bqckground-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px 0; }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .contact-main-ctn {
      display: block; } }
  @media only screen and (max-width: 480px) {
    .contact-main-ctn {
      display: block; } }
  .contact-main-ctn .about-title {
    color: #ff5e39;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding-top: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .about-title {
        font-size: 33px;
        padding-top: 50px; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .about-title {
        font-size: 33px;
        padding-top: 30px; } }
  .contact-main-ctn .about-title2 {
    color: #ff5e39;
    font-size: 73px;
    text-transform: uppercase;
    font-weight: 900;
    padding-bottom: 100px; }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .about-title2 {
        font-size: 33px;
        padding-bottom: 50px; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .about-title2 {
        font-size: 33px;
        padding-bottom: 30px; } }
  .contact-main-ctn .contact-content-ctn {
    display: flex;
    justify-content: space-around; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .contact-main-ctn .contact-content-ctn {
        display: block;
        margin: auto; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .contact-main-ctn .contact-content-ctn {
        display: block;
        margin: auto; } }
    @media only screen and (max-width: 480px) {
      .contact-main-ctn .contact-content-ctn {
        margin: auto;
        display: block; } }
    .contact-main-ctn .contact-content-ctn .contact-image-ctn {
      width: 40%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      @media only screen and (max-width: 480px) {
        .contact-main-ctn .contact-content-ctn .contact-image-ctn {
          width: 100%; } }
      .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
        width: 350px;
        border-radius: 50%;
        margin: auto;
        overflow: hidden; }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
            width: 250px; } }
        @media only screen and (max-width: 480px) {
          .contact-main-ctn .contact-content-ctn .contact-image-ctn .contact-image {
            width: 250px; } }
    .contact-main-ctn .contact-content-ctn .contact-ctn {
      width: 60%; }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      @media only screen and (max-width: 480px) {
        .contact-main-ctn .contact-content-ctn .contact-ctn {
          width: 100%; } }
      .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
        margin: auto;
        text-align: left; }
        @media only screen and (min-width: 641px) and (max-width: 960px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        @media only screen and (min-width: 481px) and (max-width: 640px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        @media only screen and (max-width: 480px) {
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn {
            width: 90%;
            text-align: center; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
          font-size: 34px;
          font-weight: 800;
          font-style: italic;
          margin: 25px 0px; }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
              font-size: 24px; } }
          @media only screen and (max-width: 480px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-subtitle {
              font-size: 24px; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
          width: 80%;
          word-wrap: break-word;
          font-size: 25px;
          font-weight: 500;
          padding-top: 25px;
          padding-bottom: 50px;
          line-height: 45px; }
          @media only screen and (min-width: 641px) and (max-width: 960px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
          @media only screen and (min-width: 481px) and (max-width: 640px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
          @media only screen and (max-width: 480px) {
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .about-description {
              width: 100%; } }
        .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn {
          width: 100%; }
          .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
            margin: 5px;
            transition: 0.2s ease-in; }
            .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo:hover {
              transform: scale(1.1); }
            @media only screen and (min-width: 481px) and (max-width: 640px) {
              .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
                height: 50px; } }
            @media only screen and (max-width: 480px) {
              .contact-main-ctn .contact-content-ctn .contact-ctn .about-ctn .contact-logo-ctn .contact-logo {
                height: 50px; } }
