/*Media Queries*/
/*Transitions*/
.skills-section {
  background-image: url("../../../../style/images/bqckground-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: auto;
  width: 100%; }
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (min-width: 641px) and (max-width: 960px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (min-width: 481px) and (max-width: 640px) {
    .skills-section {
      max-height: none; } }
  @media only screen and (max-width: 480px) {
    .skills-section {
      max-height: none; } }
  .skills-section .skills-ctn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    flex-wrap: wrap; }
    @media only screen and (min-width: 641px) and (max-width: 960px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        max-width: 90%;
        margin: auto; } }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        max-width: 90%;
        margin: auto; } }
    @media only screen and (max-width: 480px) {
      .skills-section .skills-ctn {
        display: block;
        width: 90%;
        margin: auto;
        max-width: 90%; } }
    .skills-section .skills-ctn .dev-skills-ctn {
      margin: 150px 15px;
      width: 20%; }
      @media only screen and (min-width: 961px) and (max-width: 1200px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 50px auto;
          width: 40%; } }
      @media only screen and (min-width: 641px) and (max-width: 960px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }
      @media only screen and (min-width: 481px) and (max-width: 640px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }
      @media only screen and (max-width: 480px) {
        .skills-section .skills-ctn .dev-skills-ctn {
          margin: 30px auto;
          width: 100%;
          padding-top: 20px; } }
